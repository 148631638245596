<template>
  <v-container id="user-table" fluid tag="section">
    <base-panel icon="mdi-account-group" :title="$t('group.groups')">
      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('group.delete group')"
        :confirmation="$t('group.group deleted')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">
          {{ $t("group.confirm delete group", item) }}
        </template>

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">
            {{ error.message }}</v-alert
          >
        </template>
      </delete-dialog>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{
          'items-per-page-options': [20, 50, 100],
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="addGroup">
              <v-icon left>mdi-account-plus</v-icon>
              {{ $t("group.Add group") }}
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="redirectEdit(item.id)"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import DeleteDialog from "@/components/crud/DeleteDialog";

import crudMixin from "mixins/crudTable";

export default {
  components: {
    DeleteDialog,
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/target-group/",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: this.$t("Name"), value: "name" },
        { text: this.$t("group.description"), value: "description" },
        { text: this.$t("group.participants"), value: "participants" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
    };
  },

  methods: {
    addGroup() {
      this.$router.push("/clients/groups/create");
    },
    redirectEdit(id) {
      this.$router.push("/clients/groups/" + id);
    },
  },
};
</script>
